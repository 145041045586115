import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useQueryParam, NumberParam } from "use-query-params"
import qs from "qs"
import axios from "axios"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Register = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [address, setAddress] = useState("")
  const [service, setService] = useState("")

  const [userID, setUserID] = useQueryParam("id", NumberParam)

  let data = {
    first_name: firstName,
    last_name: lastName,
    address: address,
    service: service,
    user_id: userID,
  }

  const handleSubmit = e => {
    e.preventDefault()
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
    fetch(
      "https://hook.integromat.com/47xs7i3123zo5rx48yd9svulv1gh1235",
      requestOptions
    ).then(
      response =>
        (window.location.href = `https://m.me/pipumustikka?ref=1613601833962`)
    )

    // async function fetchURLs() {
    //   // Promise.all() lets us coalesce multiple promises into a single super-promise
    //   const [
    //     firstNameResponse,
    //     lastNameResponse,
    //     addressResponse,
    //     serviceResponse,
    //   ] = await Promise.all([
    //     axios({
    //       method: "post",
    //       url: `https://api.chatrace.com/user/${userID}/custom_fields/168223`,
    //       data: qs.stringify({
    //         value: firstName,
    //       }),
    //       headers: {
    //         "content-type": "application/x-www-form-urlencoded;charset=utf-8",

    //         "X-ACCESS-TOKEN":
    //           "106708447786884.IEjVBOhPeGflyL6vyN8hfaGex0BannMS",
    //       },
    //     }),
    //     axios({
    //       method: "post",
    //       url: `https://api.chatrace.com/user/${userID}/custom_fields/328795`,
    //       data: qs.stringify({
    //         value: lastName,
    //       }),
    //       headers: {
    //         "content-type": "application/x-www-form-urlencoded;charset=utf-8",

    //         "X-ACCESS-TOKEN":
    //           "106708447786884.IEjVBOhPeGflyL6vyN8hfaGex0BannMS",
    //       },
    //     }),
    //     axios({
    //       method: "post",
    //       url: `https://api.chatrace.com/user/${userID}/custom_fields/465000`,
    //       data: qs.stringify({
    //         value: address,
    //       }),
    //       headers: {
    //         "content-type": "application/x-www-form-urlencoded;charset=utf-8",

    //         "X-ACCESS-TOKEN":
    //           "106708447786884.IEjVBOhPeGflyL6vyN8hfaGex0BannMS",
    //       },
    //     }),
    //     axios({
    //       method: "post",
    //       url: `https://api.chatrace.com/user/${userID}/custom_fields/231054`,
    //       data: qs.stringify({
    //         value: service,
    //       }),
    //       headers: {
    //         "content-type": "application/x-www-form-urlencoded;charset=utf-8",

    //         "X-ACCESS-TOKEN":
    //           "106708447786884.IEjVBOhPeGflyL6vyN8hfaGex0BannMS",
    //       },
    //     }),
    //   ])
    //   const firstNameJson = await firstNameResponse.json()
    //   const lastNameJson = await lastNameResponse.json()
    //   const addressJson = await addressResponse.json()
    //   const serviceNameJson = await serviceResponse.json()

    //   return [firstNameJson, lastNameJson, addressJson, serviceNameJson]
    // }
    // fetchURLs()
    //   .then(([firstNameJson, lastNameJson, addressJson, serviceNameJson]) => {
    //     console.log("It works")
    //   })
    //   .catch(error => {
    //     console.log("****", error)
    //   })

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    //   },
    //   data: qs.stringify({
    //     value: service,
    //   }),
    // }
    // fetch(
    //   `https://api.chatrace.com/user/${userID}/custom_fields/465000`,
    //   requestOptions
    // ).then(
    //   response => console.log("****TEST")
    //   //(window.location.href = `https://m.me/pipumustikka?ref=1613601833962`)
    // )
  }

  return (
    <Layout>
      <Seo title="Đăng ký dịch vụ Mekong SPa" />
      <h1>Đăng ký dịch vụ </h1>
      <p>
        Xin vui lòng điền các form dưới đây để đăng ký sử dụng dịch vụ của
        Mekong Spa.
      </p>
      <p>
        Firstname: {firstName}, last name: {lastName}, address: {address},
        service:{service}
      </p>
      <p>User ID: {userID}</p>
      <form onSubmit={handleSubmit}>
        <label>
          Tên <br />
          <input
            name="firstName"
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Họ <br />
          <input
            name="lastName"
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Địa chỉ <br />
          <input
            name="address"
            type="text"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </label>
        <br />
        <label>
          Dịch vụ <br />
          <select value={service} onChange={e => setService(e.target.value)}>
            <option value="Sau sinh cho bé">Dịch vụ sau sinh cho bé</option>
            <option value="Sau sinh cho mẹ">Dịch vụ sau sinh cho mẹ</option>
            <option value="Hồ bơi thủy liệu">Hồ bơi thuỷ liệu</option>
          </select>
        </label>
        <br />
        <button type="submit">Đăng ký</button>
      </form>
    </Layout>
  )
}

export default Register
